import React from 'react'
import styles from './testimonial.module.css'
import Icon from '../icons/icon'
import Carousel from 'nuka-carousel'

const Testimonial = ({ testimonials }) => (
  <div className={styles.testimonial}>
    <Icon icon="Quotes" className={styles.icon} />
    <Carousel
      withoutControls
      autoplay
      wrapAround
      autoplayInterval={5000}
      initialSlideWidth={500}
      heightMode="max"
    >
      {testimonials &&
        testimonials.map(function(testimonial, i) {
          return (
            <div key={i} className={styles.internalWrapper}>
              <div
                className={styles.body}
                dangerouslySetInnerHTML={{
                  __html: testimonial.body.childMarkdownRemark.html,
                }}
              />
              {testimonial.author ? (
                <span className={styles.author}>{testimonial.author}</span>
              ) : null}
            </div>
          )
        })}
    </Carousel>
  </div>
)

export default Testimonial
