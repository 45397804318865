import React from 'react'
import Layout from '../components/layout/'
import Callout from '../components/callout/'
import backgroundImage from '../static/headerbackground.jpg'
import Header from '../components/header'
import PageWrapper from '../components/pageWrapper'
import Testimonial from '../components/testimonial'
import Donors from '../components/donors'
import styles from './styles/index.module.css'

const IndexPage = ({ data }) => {
  const { announcement, donors, stats, testimonials } = data.home.edges[0].node

  return (
    <div>
      <Layout callout={<Callout />}>
        <Header
          backgroundImage={backgroundImage}
          stats={stats}
          booksSent={stats.booksSent}
        />
        <PageWrapper>
          <div className={styles.contentBlock}>
            <h1>{announcement.title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: announcement.body.childMarkdownRemark.html,
              }}
            />
          </div>
          <section>
            <Testimonial testimonials={testimonials} />
          </section>
          <section>
            <div className={styles.contentBlock}>
              <h1>Our Mission</h1>
              <p>
                We believe that EVERY child deserves to get started on the right
                page. Our mission is to ensure that every young child in our
                community has access to age-appropriate books in their homes.
                <br />
                <br />
                Through Imagination Library, we provide children under the age
                of five, living in the Grand Junction service area, the
                opportunity to register to receive a brand new, age specific
                book every single month until their 5th Birthday. The books are
                mailed straight to the child at no cost to the family.
                <br />
                <br />
                Join us by investing in the children of our community! Through
                the gift of books, we can ensure young children develop early
                literacy skills that are essential in preparing them for school
                and a love of reading and learning to last a lifetime. Together
                we can change lives.
              </p>
            </div>
          </section>
        </PageWrapper>
        <section>
          <Donors donors={donors} />
        </section>
      </Layout>
    </div>
  )
}

export const indexPageQuery = graphql`
  query indexPageQuery {
    home: allContentfulHomePage {
      edges {
        node {
          stats {
            id
            booksSent
            kidsRegistered
          }
          announcement {
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          testimonials {
            author
            body {
              childMarkdownRemark {
                html
              }
            }
          }
          donors {
            name
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
