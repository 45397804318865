import React, { Component } from 'react'
import styles from './donors.module.css'

class Donors extends Component {
  render() {
    const { donors } = this.props

    return (
      <div className={styles.wrapper}>
        <h1>Our Donors</h1>
        <div className={styles.donors}>
          {donors.map(function(donor) {
            return (
              <div className={styles.donor} key={donor.name}>
                <img src={donor.image.file.url} alt={donor.name} />
                <span>{donor.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}

export default Donors
